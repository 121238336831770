'user strict';

var Loading = function($target){
    // set the element
    var self = this;
    self.$el = $target;

    $(window).on('load',function(){
        self.hideLoading();
    })

}

Loading.prototype.showLoading = function(){
    var self = this;

    TweenMax.set(self.$el,{x:0,alpha:0});
    TweenMax.to(self.$el,0.25,{alpha:1});
};

Loading.prototype.hideLoading = function(){
    var self = this;
    TweenMax.to(self.$el,0.25,{alpha:0,delay:1.3,onComplete:function(){
        TweenMax.set(self.$el,{x:-100+'%'});
    }});
};

module.exports = Loading;
