'use strict';
//window.PIXI = require('./vendor/pixi');

var ParticleNumberFallback = function($target){
    var self = this;
    self.$el = $target;

    self.stage = new PIXI.Container();

    self.dotWidth = 96;
    self.dotHeight = 96;
    self.dotTotal = self.dotWidth * self.dotHeight;

    self.isWebGL = false;

    // fix for black border in safari. must update pixi to version that works wih this
    /*self.renderer = PIXI.autoDetectRenderer(600, 600, { antialias: false, backgroundColor:0xffdc00, transparent:true });
    */
    self.renderer = PIXI.autoDetectRenderer(600, 600, { antialias: false, backgroundColor:0xffdc00, transparent:false });
    self.domElement = self.renderer.view;
    self.$el.append(self.domElement);

    
    self.alpha = 1;

    // the context on which we draw numbers..
    self.canvas = document.createElement('canvas');
    self.canvas.width = self.dotWidth;
    self.canvas.height = self.dotHeight;
    self.context = self.canvas.getContext('2d');

    self.text = new PIXI.Text(' ', {font:'bold 370pt Source Sans Pro', fill:0xb19900});
    self.text.anchor.set(0.5);
    self.text.position.set(300, 300);

    self.stage.addChild(self.text);

    self.overlay = new PIXI.Sprite.fromImage('assets/images/fallback-overlay.png')
    self.stage.addChild( self.overlay )
    // webGL stuff..
    self.animate();
};

ParticleNumberFallback.prototype.initWebGL = function(){
   
};

ParticleNumberFallback.prototype.setValue = function(string)
{
   var self = this;
   
   TweenMax.to(self.text, 0.2, {alpha:0, onComplete:function(){

        self.text.text = string;
        TweenMax.to(self.text, 0.3, {alpha:1});

   }});

};



ParticleNumberFallback.prototype.animate = function(){
    var self = this;

    if (window.innerWidth > 993) 
    { 
        // 993 being the large breakpoint
        self.renderer.render(self.stage);
    }

    requestAnimationFrame( self.animate.bind(self) );
};


module.exports = ParticleNumberFallback;
