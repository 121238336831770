'use strict';

// Constructor
var MultiSelect = function($target) {
    // set the element
    var self = this;
    self.$el = $target;
    self.$multiselectOptions = $target.find('.multiselect-option');

    // apply 'selected' class to first option
    //self.$multiselectOptions.first().addClass('selected');

    // select option
    self.$multiselectOptions.on('click', function(e){
        e.stopImmediatePropagation();
        e.preventDefault();
        if(!window.isTransitioning){
            if ($(this).hasClass('selected')){
                $(this).removeClass('selected');
            } else {
                $(this).addClass('selected');
            }
        }
    });

};

module.exports = MultiSelect;
