'use strict';
//window.PIXI = require('./vendor/pixi');

var ParticleNumber = function($target){
    var self = this;
    self.$el = $target;

    self.stage = new PIXI.Container();

    self.dotWidth = 96;
    self.dotHeight = 96;
    self.dotTotal = self.dotWidth * self.dotHeight;

    self.isWebGL = false;

    // fix for black border in safari. must update pixi to version that works wih this
    /*self.renderer = PIXI.autoDetectRenderer(600, 600, { antialias: false, backgroundColor:0xffdc00, transparent:true });
    *///
    self.renderer = PIXI.autoDetectRenderer(600, 600, { antialias: false, backgroundColor:0xffdc00, transparent:true });
    self.renderer._backgroundColorRgba[3] = 1;

    self.domElement = self.renderer.view;
    self.$el.append(self.domElement);

    self.size = 6;

    self.randimizerArray = [];
    for (var i = 0; i < self.dotTotal; i++) {
        self.randimizerArray[i] = i;
    };

    self.alpha = 1;

    // the context on which we draw numbers..
    self.canvas = document.createElement('canvas');
    self.canvas.width = self.dotWidth;
    self.canvas.height = self.dotHeight;
    self.context = self.canvas.getContext('2d');

    self.pixiCanvas = new PIXI.CanvasRenderer(self.dotWidth, self.dotHeight, {transparent:true});

    // set up the dot information..
    self.dots = new Float32Array(self.dotTotal * self.size);
    self.dotsTarget = new Float32Array(self.dotTotal * self.size);
    self.dotsSpring = [];
    self.randomHelper = [];

    // randomize initial positions..
    for (var i = 0; i < self.dotsTarget.length; i+=self.size) {

        self.dotsTarget[i] = Math.random() * 400;
        self.dotsTarget[i+1] = Math.random() * 400;

        self.dotsTarget[i+2] = 0;
        self.dotsTarget[i+3] = 0;
        self.dotsTarget[i+4] = 0;
        self.dotsTarget[i+5] = 0;
        self.dotsSpring[i/6] = {x:0, y:0, dx:0, dy:0};
        self.randomHelper.push(i * 3);
    };
    self.isOut = false;
    // webGL stuff..
    self.initWebGL();
    self.easey = 0;
    self.animate();

    self.textHasLoaded = false;
    this.loader = new PIXI.loaders.Loader();
    this.loader.add('assets/images/frutiger_nos.xml')
    this.loader.load(function(){
        
        self.textHasLoaded = true;   
        self.bitmapText = new PIXI.BitmapText(' ', {font: "70px Frutiger LT Std", fill:0xb19900,align: "right"});
       // self.pixiCanvas.addChild( self.bitmapText);
    });
};

ParticleNumber.prototype.initWebGL = function(){
    var self = this;
    var glCore = PIXI.glCore;
    var gl = self.renderer.gl;


    // make some buffers..
    var indices = new Uint16Array(self.dotTotal);
    for (var i = 0; i < indices.length; i++) {
        indices[i] = i;
    };

    var indexBuffer = new glCore.GLBuffer.createIndexBuffer(gl, indices);
    self.particalBuffer = glCore.GLBuffer.createVertexBuffer(gl, self.dots);

    // make a shader..§
    var vertPoints = [
        'precision highp float;',
        'attribute vec2 aVertexPosition;',
        'attribute vec4 aVertexColor;',
        'uniform mat3 projectionMatrix;',
        'varying vec4 color;',
        'void main(void){color = aVertexColor;gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);gl_PointSize = 2.0;',
        '}'
    ].join('\n');

    var fragPoints = [
        'precision highp float;varying vec4 color;',
        'void main(void){gl_FragColor = vec4(color.rgb * color.a, color.a);',
        '}'
    ].join('\n')

    self.pointsShader = new glCore.GLShader(gl, vertPoints, fragPoints);


    // make a vao..
    self.vao = new glCore.VertexArrayObject(gl)
    self.vao.addIndex(indexBuffer)
    self.vao.addAttribute(self.particalBuffer, self.pointsShader.attributes.aVertexPosition, gl.FLOAT, false, self.size * 4, 0);
    self.vao.addAttribute(self.particalBuffer, self.pointsShader.attributes.aVertexColor, gl.FLOAT, false, self.size * 4, 2 * 4);
};

ParticleNumber.prototype.setValue = function(string){
    var self = this;
    var context = self.context;
    var dotsTarget = self.dotsTarget;

 //   context.clearRect(0,0,self.dotWidth, self.dotHeight);
  //  context.fillStyle = '#b19900';
   // context.font = 'bold 70pt Source Sans Pro';

    if(this.bitmapText)
    {
        self.bitmapText.text = String(string);
        self.bitmapText.position.x = 96/2 - self.bitmapText.width/2 - 2;
        self.bitmapText.position.y = 96/2 - self.bitmapText.height/2;
        this.pixiCanvas.render( self.bitmapText)
    }

    var context = this.pixiCanvas.context;
  //  var numberWidth = context.measureText(string.toString()).width
  //  context.fillText(string.toString(), 96/2 - numberWidth/2, 82);


    var colorData = context.getImageData(0,0,self.dotWidth,self.dotHeight).data;

    var index = self.randimizerArray;

    var centerX = 300;
    var centerY = 300;

    self.isOut = true;
    self.alpha = 2;


    for (var i = 0; i < index.length; i++) {

        var randomI = index[i];

        var iStep = randomI * self.size;
      //  console.log(ii);
        var spring = self.dotsSpring[i];

        spring.x =  (i % self.dotWidth) * gap;
        spring.y =  ((i / self.dotWidth) | 0) * gap;

        var dx = (spring.x - centerX);
        var dy = (spring.y - centerY);
        //dx += Math.random();

        var dis = Math.sqrt(dx * dx + dy * dy);
        if(dis === 0)dis += 0.0001
        dx /= dis;
        dy /= dis;

        var power = 30
        spring.dx = dx * (Math.random() * power);
        spring.dy = dy * (Math.random() * power);

        spring.dx += (Math.random()-0.5) * 4;
        spring.dy += (Math.random()-0.5) * 8;

        var gap = 6
        dotsTarget[iStep] = (i % self.dotWidth) * gap;
        dotsTarget[iStep+1] = ((i / self.dotWidth) | 0) * gap;

        var colorIndex = i * 4;

        dotsTarget[iStep+2] = colorData[colorIndex] / 256;
        dotsTarget[iStep+3] = colorData[colorIndex+1] / 256;
        dotsTarget[iStep+4] = colorData[colorIndex+2] / 256;
        dotsTarget[iStep+5] = colorData[colorIndex+3] / 256;
    }

};

var easeInExpo = function (x, t, b, c, d)
{
    return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
}

ParticleNumber.prototype.moveParticles = function() {
    var self = this;
    var dotsTarget = self.dotsTarget;
    var dots = self.dots;

    if(self.isOut)
    {
        self.alpha *= 0.90;

        if(self.alpha < 0.2)
        {
            self.isOut = false;
            var r = self.shuffle6(self.dots);
        }
    }
    else
    {
        self.alpha += 0.1;
        if(self.alpha > 1)
        {
            self.alpha = 1;
        }
    }

    for (var i = 0; i < dots.length; i+=6) {

        var spring = self.dotsSpring[i / 6];

        if(self.isOut)
        {

            spring.x += spring.dx;
            spring.y += spring.dy;

            spring.dx *= 0.95;
            spring.dy *= 0.95;
            dots[i] = spring.x
            dots[i+1] = spring.y

        }
        else
        {
            dots[i] += ( dotsTarget[i] - dots[i] ) * 0.1;
            dots[i+1] += ( dotsTarget[i+1] - dots[i+1] ) * 0.1;

            dots[i+2] += ( dotsTarget[i+2] - dots[i+2] ) * 0.1;
            dots[i+3] += ( dotsTarget[i+3] - dots[i+3] ) * 0.1;
            dots[i+4] += ( dotsTarget[i+4] - dots[i+4] ) * 0.1;
            dots[i+5] += ( dotsTarget[i+5] - dots[i+5] ) * 0.1;
        }
    }

    self.particalBuffer.upload();
};


ParticleNumber.prototype.shuffle6 = function(array) {
    var self = this;
    var currentIndex = array.length/6, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    var real = currentIndex * 6;
    var realRandom = randomIndex * 6;
    // And swap it with the current element.
    var temporaryValue = array[real];
    var temporaryValue1 = array[real + 1];
    var temporaryValue2 = array[real + 2];
    var temporaryValue3 = array[real + 3];
    var temporaryValue4 = array[real + 4];
    var temporaryValue5 = array[real + 5];

    array[real] = array[realRandom];
    array[real + 1] = array[realRandom + 1];
    array[real + 2] = array[realRandom + 2];
    array[real + 3] = array[realRandom + 3];
    array[real + 4] = array[realRandom + 4];
    array[real + 5] = array[realRandom + 5];

    array[realRandom] = temporaryValue;
    array[realRandom + 1] = temporaryValue1;
    array[realRandom + 2] = temporaryValue2;
    array[realRandom + 3] = temporaryValue3;
    array[realRandom + 4] = temporaryValue4;
    array[realRandom + 5] = temporaryValue5;
  }

  return array;
};


ParticleNumber.prototype.animate = function(){
    var self = this;
    self.moveParticles();

    var gl = self.renderer.gl;

    if (window.innerWidth > 993) { // 993 being the large breakpoint
        self.renderer.render(self.stage);

        self.renderer.bindShader(self.pointsShader);

        self.vao.bind()
        .draw(gl.POINTS, self.dotTotal)
        .unbind();
    }

    requestAnimationFrame( self.animate.bind(self) );
};


module.exports = ParticleNumber;
