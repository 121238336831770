'use strict';
//window.PIXI = require('./vendor/pixi');
var particleNumber = require('./particleNumber');

var lightningbolt = function($target)
{
    particleNumber.call(this, $target);

    this.loader = new PIXI.loaders.Loader();

    this.loaded = false;
    this.showLightning = false;

    this.loader.add('assets/images/lightning.png');
    this.loader.load(function(){
       // alert("!")
       this.loaded = true;

       if(this.showLightning)
       {
            this.setValue();
       }
    }.bind(this))//.add('assets/image/lightning.png');

}

lightningbolt.prototype = Object.create( particleNumber.prototype );

lightningbolt.prototype.setValue = function()
{
    if(!this.loaded)
    {
        this.showLightning = true;
        return;
    }

    var lightning = PIXI.Sprite.fromImage('assets/images/lightning.png');

    var self = this;
    var context = self.context;
    var dotsTarget = self.dotsTarget;

    context.clearRect(0,0,self.dotWidth, self.dotHeight);
    context.drawImage(lightning.texture.baseTexture.source, 0, 0)


    var colorData = context.getImageData(0,0,self.dotWidth,self.dotHeight).data;

    var index = self.randimizerArray;

    var centerX = 300;
    var centerY = 300;

    self.isOut = true;
    self.alpha = 2;


    for (var i = 0; i < index.length; i++) {

        var randomI = index[i];

        var iStep = randomI * self.size;
      //  console.log(ii);
        var spring = self.dotsSpring[i];

        spring.x =  (i % self.dotWidth) * gap;
        spring.y =  ((i / self.dotWidth) | 0) * gap;

        var dx = (spring.x - centerX);
        var dy = (spring.y - centerY);
        //dx += Math.random();

        var dis = Math.sqrt(dx * dx + dy * dy);
        if(dis === 0)dis += 0.0001
        dx /= dis;
        dy /= dis;

        var power = 30
        spring.dx = dx * (Math.random() * power);
        spring.dy = dy * (Math.random() * power);

        spring.dx += (Math.random()-0.5) * 4;
        spring.dy += (Math.random()-0.5) * 8;

        var gap = 6
        dotsTarget[iStep] = (i % self.dotWidth) * gap;
        dotsTarget[iStep+1] = ((i / self.dotWidth) | 0) * gap;

        var colorIndex = i * 4;

        dotsTarget[iStep+2] = colorData[colorIndex] / 256;
        dotsTarget[iStep+3] = colorData[colorIndex+1] / 256;
        dotsTarget[iStep+4] = colorData[colorIndex+2] / 256;
        dotsTarget[iStep+5] = colorData[colorIndex+3] / 256;
    }

};

lightningbolt.prototype.animate = function(){
    var self = this;
    self.moveParticles();

    var gl = self.renderer.gl;

    if (window.innerWidth > 993) { // 993 being the large breakpoint
        self.renderer.render(self.stage);
        self.renderer.bindShader(self.pointsShader);
        self.vao.bind()
        .draw(gl.POINTS, self.dotTotal)
        .unbind();
    } else {
        if (!$('.bgHero').hasClass('fallback')){
            $('.bgHero').addClass('fallback');
            $('.bgHero').find('canvas').css('display','none');
        }
    }

    requestAnimationFrame( self.animate.bind(self) );

};

module.exports = lightningbolt;
