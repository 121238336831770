'use strict';

var SingleSelect = require('./../utils/singleSelect');
var MultiSelect = require('./../utils/multiSelect');
var RangeSlider = require('./../utils/rangeSlider');
var Cookies = require('./../utils/cookies');
var ParticleNumberFallback = require('./../utils/particleNumberFallback');
var ParticleNumber = require('./../utils/particleNumber');
var lightningBolt = require('./../utils/lightningbolt');

// Constructor
var QuizEngine = function($target) {
    // set the element
    var self = this;
    self.$el = $target;
    self.$questions = [];
    self.$questionPagesLeft;
    self.$questionPagesRight;
    self.$currentQuestionLeft;
    self.$currentQuestionRight;
    self.$currentRoute;
    self.$bgPagination = self.$el.find('.bgPagination');
    self.$particlePagination = self.$el.find('.particlePagination');
    self.$paginationIndicator = $('.quizPagination').find('.indicator');
    self.$paginationPercentage = $('.quizPagination').find('.percentage');
    self.$resultsPage = $('.Results');
    self.$resultsPage.archetypeTitle = self.$resultsPage.find('.archetypeTitle');
    self.$resultsPage.quote = self.$resultsPage.find('h2.quote');
    self.$resultsPage.imageHolder = self.$resultsPage.find('.imageHolder');
    self.$resultsPage.superPowerTitle = self.$resultsPage.find('h4.superPowerTitle');
    self.$resultsPage.superPowerCopy = self.$resultsPage.find('p.superPowerCopy');
    self.$resultsPage.kryptoniteTitle = self.$resultsPage.find('h4.kryptoniteTitle');
    self.$resultsPage.kryptoniteCopy = self.$resultsPage.find('p.kryptoniteCopy');
    self.$resultsPage.powerRating = self.$resultsPage.find('.powerRating');
    self.$resultsPage.aboutCopy = self.$resultsPage.find('p.aboutCopy');
    self.$resultsPage.missionCopy = self.$resultsPage.find('p.missionCopy');
    self.$resultsPage.tipsHolder = self.$resultsPage.find('ul.tipsHolder');
    self.$resultsPage.statHolder = self.$resultsPage.find('.statHolder');
    self.$quizPagination = $('.quizPagination');
    self.$archetypes = [];
    self.$questionsLoaded = false;
    self.$archetypesLoaded = false;
    self.$winningArchetype;
    self.$currentPage = 1;
    self.$resultsCookie = new Cookies();
    self.$quizPageLeft = self.$el.find('.splitContainer.left');
    self.$quizPageRight = self.$el.find('.splitContainer.right');
    self.$childrenToAnimate = '.leftTitle, .statement, .infoTitle, .leftStatement, .rightStatement, .nextButton, .finishButton, .rightTitle, .rightInstruction, .rangeSlider, .singleselect-option, .multiselect-option, .custom-select, .select-ie9';
    window.isTransitioning = false;
    self.$userAge;
    self.$ageRangeSlider;
    self.$headerLogoInQuiz = $('header a.aviva-logo');

    // constants
    self.$constants = {
        arch01:-159.114,
        arch02:-154.751,
        arch03:-142.034,
        arch04:-144.551,
        arch05:-168.019,
        arch06:-114.356,
        arch07:-143.893,
        arch08:-178.747,
        arch09:-148.072,
        arch10:-163.177
    };

    self.$score = {
        arch01:0,
        arch02:0,
        arch03:0,
        arch04:0,
        arch05:0,
        arch06:0,
        arch07:0,
        arch08:0,
        arch09:0,
        arch10:0
    };

    // load data
    self.loadQuizData();
    self.loadArchetypeData();

    // create particle pagination
    if(PIXI.utils.isWebGLSupported())
    {
        self.$particlePaging = new ParticleNumber(self.$particlePagination);
    }
    else
    {
       self.$particlePaging = new ParticleNumberFallback(self.$particlePagination);
    }

    // helper function
    self.containsObject = function(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }
};

QuizEngine.prototype.loadQuizData = function(){
    var self = this;
    // load questions, and answers json
    $.getJSON("/data/questions.json", function(data) {
      $.each(data.quizQuestions,function(key,val) {
            self.$questions.push(val);
        });
    })
    .done(function() {
        self.$questionsLoaded = true;
        self.checkForData();
    })
    .fail(function() {
        self.loadQuizData();
    });
};

QuizEngine.prototype.loadArchetypeData = function(){
    var self = this;
    // load archetypes
    $.getJSON("/data/archetypes.json", function(data) {
      $.each(data.archetypes,function(key,val) {
            self.$archetypes.push(val);
        });
    })
    .done(function() {
        self.$archetypesLoaded = true;
        self.checkForData();
    })
    .fail(function() {
        self.loadArchetypeData();
    });
};

QuizEngine.prototype.checkForData = function(){
    var self = this;
    if (self.$archetypesLoaded == true && self.$questionsLoaded == true ){
        self.buildQuestions();
    }
};

QuizEngine.prototype.buildQuestions = function(){
    var self = this;

    var getRandomInt = function(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    if (getRandomInt(1,4) < 4) {
        // 75% of traffic, remove first question
        self.$currentRoute = "A";
        self.$questions.shift();
        // add route to tracking
        AVIVA.pageData['user-route'] = "A";
    } else {
        self. $currentRoute = "B";
         // add route to tracking
        AVIVA.pageData['user-route'] = "B";
    }


    $(self.$questions).each(function(i){
        var question = self.$questions[i];
        var coefficients;
        // build question pages
        var leftSplit = $('<div />', {"class": 'leftSplit'});
        var rightSplit = $('<div />', {"class": 'rightSplit'});
        leftSplit.addClass('questionHolder question-'+(i+1)+' '+question.questionType);
        rightSplit.addClass('questionHolder question-'+(i+1)+' '+question.questionType);
        self.$quizPageLeft.find('.content').append(leftSplit);
        self.$quizPageRight.find('.content').append(rightSplit);

        // helper function to add score
        var scoreAdd = function(inputKey){
            self.addToScore(inputKey,coefficients.ARCH01,
                coefficients.ARCH02,
                coefficients.ARCH03,
                coefficients.ARCH04,
                coefficients.ARCH05,
                coefficients.ARCH06,
                coefficients.ARCH07,
                coefficients.ARCH08,
                coefficients.ARCH09,
                coefficients.ARCH10);
            self.nextQuestion();
        };

        // parse data, add event listeners, by question type
        switch(question.questionType) {
            case "USERINFO":
                // left split
                var statement = $('<div />', {"class": 'statement'});
                statement.html(question.statement);
                leftSplit.append(statement);

                // right split
                var ageTitle = $('<div />', {"class": 'infoTitle'});
                ageTitle.html("Your age");
                rightSplit.append(ageTitle);

                // user age
                self.$userAge = $('<div />', {"class": 'rangeSlider userAge'});
                $(question.age).each(function(i){
                    var option = question.age[i];
                    var rangeOption = $('<div />', {"class": 'range-option'});
                    rangeOption.attr('data-value',option);
                    //rangeOption.html(option);
                    self.$userAge.append(rangeOption);
                });
                rightSplit.append(self.$userAge);
                self.$ageRangeSlider = new RangeSlider(self.$userAge);

                // user gender
                var genderTitle = $('<div />', {"class": 'infoTitle'});
                genderTitle.html("Your gender");
                rightSplit.append(genderTitle);

                if (isIE() !== 9){
                    var customSelectGender = $('<div />', {"class": 'custom-select'});
                } else {
                    var customSelectGender = $('<div />', {"class": 'select-ie9'});
                }
                rightSplit.append(customSelectGender);

                var userGender = $('<select />',{"class": 'userGender'});
                userGender.prop('required', true);
                userGender.append('<option value="" disabled selected hidden>Select...</option>');
                $(question.gender).each(function(i){
                    var option = question.gender[i];
                    var rangeOption = $('<option />', {"value": option});
                    rangeOption.html(option);
                    userGender.append(rangeOption);
                });
                customSelectGender.append(userGender);

                $(userGender).change(function(){
                    if(userGender.val()){
                        customSelectGender.addClass('selected');
                        $(this).addClass('selected');
                    }

                    if(userGender.val() && userLocation.val()){
                        $('.nextButton').removeClass('disabled');
                    }
                });

                // user location
                var locationTitle = $('<div />', {"class": 'infoTitle'});
                locationTitle.html("Your location");
                rightSplit.append(locationTitle);

                if (isIE() !== 9){
                    var customSelectLocation = $('<div />', {"class": 'custom-select'});
                } else {
                    var customSelectLocation = $('<div />', {"class": 'select-ie9'});
                }
                rightSplit.append(customSelectLocation);

                var userLocation = $('<select />',{"class": 'userLocation'});
                userLocation.prop('required', true);
                userLocation.append('<option value="" disabled selected hidden>Select...</option>');
                $(question.location).each(function(i){
                    var option = question.location[i];
                    var rangeOption = $('<option />', {"value": option});
                    rangeOption.html(option);
                    userLocation.append(rangeOption);
                });
                customSelectLocation.append(userLocation);

                $(userLocation).change(function(){
                    if(userLocation.val()){
                        customSelectLocation.addClass('selected');
                        $(this).addClass('selected');
                    }

                    if(userGender.val() && userLocation.val()){
                        $('.nextButton').removeClass('disabled');
                    }

                });

                // next button
                var nextButton = $('<div />', {"class": 'nextButton disabled'});
                nextButton.append('Next');
                nextButton.append($('<span />', {"class": 'Icon Icon--angle-right'}));
                $('.nextButtonContainer').append(nextButton);

                $('.nextButton').on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        if (userGender.val() && userLocation.val()){
                            // add profile data to the quiz data holder
                            quizData.userAge = self.$userAge.find('.selected').attr('data-value');
                            quizData.userGender = userGender.val();
                            quizData.userLocation = userLocation.val();

                            // send data to tracking
                            AVIVA.pageData['user-age'] = quizData.userAge;
                            AVIVA.pageData['user-gender'] = quizData.userGender;
                            AVIVA.pageData['user-location'] = quizData.userLocation;

                            // bring next question
                            self.nextQuestion();

                            $(this).off();
                        }
                    }
                });


            break;
            case "QOPPS":
                // left split
                var leftTitle =  $('<div />', {"class": 'leftTitle'});
                leftTitle.html("Please tell us if you agree more with statement A or B. If you agree with both statements equally, select the middle.");
                leftSplit.append(leftTitle);

                // right split
                var rightTitle = $('<div />', {"class": 'rightTitle'});
                rightTitle.html("Select one option");
                rightSplit.append(rightTitle);

                var singleSelect = $('<div />', {"class": 'singleSelect'});
                // options
                var leftStatement = $('<div />', {"class": 'singleselect-option leftStatement'});
                leftStatement.html(question.leftStatement);
                singleSelect.append(leftStatement);

                var middleStatement = $('<div />', {"class": 'singleselect-option middleStatement'});
                middleStatement.html("Agree with both statements equally");
                singleSelect.append(middleStatement);

                var rightStatement = $('<div />', {"class": 'singleselect-option rightStatement'});
                rightStatement.html(question.rightStatement);
                singleSelect.append(rightStatement);

                rightSplit.append(singleSelect);
                new SingleSelect(singleSelect);

                // add to score
                coefficients = question.coefficients[0];

                // add event listeners
                leftStatement.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 3;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                middleStatement.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 2;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                rightStatement.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 1;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
            break;
            case "QGEN":
                // left split
                var leftTitle =  $('<div />', {"class": 'leftTitle'});
                leftTitle.html("How much do you agree or disagree with the following statement?");
                leftSplit.append(leftTitle);

                var statement = $('<div />', {"class": 'statement'});
                statement.html(question.statement);
                leftSplit.append(statement);

                // right split
                var rightTitle = $('<div />', {"class": 'rightTitle'});
                rightTitle.html("Select one option");
                rightSplit.append(rightTitle);

                var singleSelect = $('<div />', {"class": 'singleSelect'});
                // options
                var agree = $('<div />', {"class": 'singleselect-option agree'});
                agree.html("Agree");
                singleSelect.append(agree);

                var disagree = $('<div />', {"class": 'singleselect-option disagree'});
                disagree.html("Disagree");
                singleSelect.append(disagree);

                var notsure = $('<div />', {"class": 'singleselect-option notsure'});
                notsure.html("Not Sure");
                singleSelect.append(notsure);

                rightSplit.append(singleSelect);
                new SingleSelect(singleSelect);

                var rightInstruction = $('<div />', {"class": 'rightInstruction'});
                rightInstruction.html("Please try and suggest which way you would lean if really pushed. If you really don’t know, please select ‘Not Sure’");
                rightSplit.append(rightInstruction);

                // add to score
                coefficients = question.coefficients[0];

                // add event listeners
                agree.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 3;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                notsure.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 2;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                disagree.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 1;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
            break;
            case "QKNOW":
                // left split
                var statement = $('<div />', {"class": 'statement'});
                statement.html(question.statement);
                leftSplit.append(statement);

                var leftStatement = $('<div />', {"class": 'leftStatement'});
                leftStatement.html(question.leftStatement);
                rightSplit.append(leftStatement);

                var singleSelect = $('<div />', {"class": 'singleSelect'});
                // options
                var opt1 = $('<div />', {"class": 'singleselect-option opt1'});
                opt1.html("<span>1</span>");
                singleSelect.append(opt1);

                var opt2 = $('<div />', {"class": 'singleselect-option opt2'});
                opt2.html("<span>2</span>");
                singleSelect.append(opt2);

                var opt3 = $('<div />', {"class": 'singleselect-option opt3'});
                opt3.html("<span>3</span>");
                singleSelect.append(opt3);

                var opt4 = $('<div />', {"class": 'singleselect-option opt4'});
                opt4.html("<span>4</span>");
                singleSelect.append(opt4);

                var opt5 = $('<div />', {"class": 'singleselect-option opt5'});
                opt5.html("<span>5</span>");
                singleSelect.append(opt5);

                rightSplit.append(singleSelect);
                new SingleSelect(singleSelect);

                var rightStatement = $('<div />', {"class": 'rightStatement'});
                rightStatement.html(question.rightStatement);
                rightSplit.append(rightStatement);

                // add to score
                coefficients = question.coefficients[0];

                // add event listeners
                opt1.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 1;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt2.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 2;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt3.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 3;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt4.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 4;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt5.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 5;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
            break;
            case "QBUDGET":
                // left split
                var statement = $('<div />', {"class": 'statement'});
                statement.html(question.statement);
                leftSplit.append(statement);

                // right split
                var rightTitle = $('<div />', {"class": 'rightTitle'});
                rightTitle.html("Select one option");
                rightSplit.append(rightTitle);

                var singleSelect = $('<div />', {"class": 'singleSelect'});
                // options
                var opt1 = $('<div />', {"class": 'singleselect-option opt1'});
                opt1.html(question.answers[0].answerCopy1);
                singleSelect.append(opt1);

                var opt2 = $('<div />', {"class": 'singleselect-option opt2'});
                opt2.html(question.answers[0].answerCopy2);
                singleSelect.append(opt2);

                var opt3 = $('<div />', {"class": 'singleselect-option opt3'});
                opt3.html(question.answers[0].answerCopy3);
                singleSelect.append(opt3);

                var opt4 = $('<div />', {"class": 'singleselect-option opt4'});
                opt4.html(question.answers[0].answerCopy4);
                singleSelect.append(opt4);

                var opt5 = $('<div />', {"class": 'singleselect-option opt5'});
                opt5.html(question.answers[0].answerCopy5);
                singleSelect.append(opt5);

                rightSplit.append(singleSelect);
                new SingleSelect(singleSelect);

                // add to score
                coefficients = question.coefficients[0];

                // add event listeners
                opt1.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 1;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt2.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 2;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt3.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 3;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt4.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 4;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
                opt5.on('click',function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        var inputKey = 5;
                        scoreAdd(inputKey);
                        $(this).off();
                    }
                });
            break;
            case "QFEEL":
                var toCalculate = [];
                var dontknowinarray = false;
                // left split
                var statement = $('<div />', {"class": 'statement'});
                statement.html(question.statement);
                leftSplit.append(statement);

                // right split
                var rightTitle = $('<div />', {"class": 'rightTitle'});
                rightTitle.html("Select all that apply");
                rightSplit.append(rightTitle);

                var multiSelect = $('<div />', {"class": 'multiSelect'});
                rightSplit.append(multiSelect);

                $(question.answers).each(function(i){
                    var answer = question.answers[i];
                    var answerCoefficient = answer.coefficients[0];

                    var opt = $('<div />', {"class": 'multiselect-option'});
                    var optIcon = $('<span />', {"class": 'optionIcon'});
                    opt.append(optIcon);
                    opt.append(answer.answer);
                    multiSelect.append(opt);

                    opt.on('click',function(e){
                        e.preventDefault();
                        if(!window.isTransitioning){
                            // answer mechanism
                            var answerNumber = $(this).index()+1;
                            var answersTotal = question.answers.length;
                            if (answerNumber !== answersTotal){
                                $(this).siblings(":last").removeClass('selected');
                                if (dontknowinarray == true){
                                    toCalculate.shift();
                                    dontknowinarray = false;
                                }

                                if(self.containsObject(answerCoefficient,toCalculate)){
                                    var k = toCalculate.indexOf(answerCoefficient);
                                    toCalculate.splice(k,1);
                                } else {
                                    toCalculate.push(answerCoefficient);
                                }
                            } else {
                                // 'don't know' answer
                                $(this).siblings().removeClass('selected');
                                toCalculate = [];
                                if (!$(this).hasClass('selected')){
                                    dontknowinarray = true;
                                    toCalculate.push(answerCoefficient);
                                } else {
                                    dontknowinarray = false;
                                }
                            }

                            // check finish button
                            if(toCalculate.length != 0){
                                $('.finishButton').removeClass('disabled')
                            } else {
                                $('.finishButton').addClass('disabled')
                            }
                        }
                    });

                });

                new MultiSelect(multiSelect);

                var finishButton = $('<div />', {"class": 'finishButton disabled'});
                finishButton.html("That's it!");
                $('.finishButtonContainer').append(finishButton);

                $('.finishButton').on('click', function(e){
                    e.preventDefault();
                    if(!window.isTransitioning){
                        if (toCalculate.length > 0) {
                            toCalculate.forEach(function(item){
                                var coefficients = item;
                                self.addToScore(1,coefficients.ARCH01,
                                    coefficients.ARCH02,
                                    coefficients.ARCH03,
                                    coefficients.ARCH04,
                                    coefficients.ARCH05,
                                    coefficients.ARCH06,
                                    coefficients.ARCH07,
                                    coefficients.ARCH08,
                                    coefficients.ARCH09,
                                    coefficients.ARCH10);
                            });
                            // process result
                            self.processResult();
                            $(this).off();
                        }
                    }
                });
            break;
        }

    })

    // add pages to the array
    self.$questionPagesLeft = self.$quizPageLeft.find('.questionHolder');
    self.$questionPagesRight = self.$quizPageRight.find('.questionHolder');
    self.$currentQuestionLeft = self.$questionPagesLeft.first();
    self.$currentQuestionRight = self.$questionPagesRight.first();
    TweenMax.set(self.$currentQuestionLeft.children(),{alpha:0});
    TweenMax.set(self.$currentQuestionRight.children(),{alpha:0});
    self.$currentQuestionLeft.addClass('current');
    self.$currentQuestionRight.addClass('current');
    // set the size on resize
    $(document).on('window.resize', lodashBind(self.setSize, self));
    $(window).trigger('window.resize');
};

QuizEngine.prototype.bringFirstQuestion = function(){
    var self  = this;
    window.isTransitioning = true;
    $(self.$childrenToAnimate).addClass('inactive');

    // animate container heights
    TweenMax.to(self.$quizPageLeft.find('.content'),0,{height:self.$currentQuestionLeft.height(),ease:Strong.easeOut});
    TweenMax.to(self.$quizPageRight.find('.content'),0,{height:self.$currentQuestionRight.height(),ease:Strong.easeOut});

    var toAnimate = [];
    self.$currentQuestionLeft.find(self.$childrenToAnimate).each(function(i){
        toAnimate.push($(this));
    });

    self.$currentQuestionRight.find(self.$childrenToAnimate).each(function(i){
        toAnimate.push($(this));
    });

    if(self.$currentRoute == "B") {
        $('.nextButtonContainer').css('display','block');
        toAnimate.push($('.nextButton'));
    } else {
        $('.push').css('display','none');
        $('.nextButtonContainer').css('display','none');
        $('.singleSelect').css('opacity','1');
    }

    $(toAnimate).each(function(i){
        var item = $(this);
        var i = i;
        TweenMax.fromTo(item,0.5,{alpha:0,x:100+'%'},{alpha:1,x:0+'%',force3D:true,ease:Strong.easeOut,delay:0.25+(i*0.1),onStart:function(){
                if (i==0){
                // fix for ie11: resize range slider before it animates so it displays the correct lengths of the component elements
                if (self.$currentRoute == "B") {
                    self.$ageRangeSlider.setSize();
                }
            }

        },onComplete:function(){
            if (i==toAnimate.length-1){
                window.isTransitioning = false;
                $(self.$childrenToAnimate).removeClass('inactive');
            }
        }});
    });

    // disable header link while on quiz
    self.$headerLogoInQuiz.css('pointer-events','none');

};

QuizEngine.prototype.nextQuestion = function(){
    var self = this;
    var toAnimateOut = [];
    var toAnimateIn = [];
    // resets childrenToAnimate list
    self.$childrenToAnimate = '.leftTitle, .statement, .infoTitle, .leftStatement, .rightStatement, .nextButton, .finishButton, .rightTitle, .rightInstruction, .rangeSlider, .singleselect-option, .multiselect-option, .custom-select, .select-ie9';

    // if current question has a next question, bring it, otherwise, finish the quiz
    if (self.$currentQuestionLeft.next()){
        window.isTransitioning = true;
        $(self.$childrenToAnimate).addClass('inactive');
        self.$currentQuestionLeft.next().addClass('next');
        self.$currentQuestionRight.next().addClass('next');

        // scroll window up
        if($(window).scrollTop() > 0 || $(self.$el).scrollTop() > 0 ) {
            TweenMax.to($(self.$el),0.5, {scrollTo:{y:(0)},force3D:true, ease:Strong.easeOut});
            TweenMax.to($(window),0.5, {scrollTo:{y:(0)},force3D:true, ease:Strong.easeOut});
        }

        // keep common elements between questions (remove from childrenToAnimate)
        if (self.$currentQuestionLeft.hasClass('QOPPS') && self.$currentQuestionLeft.next().hasClass('QOPPS')) {
            self.$childrenToAnimate = self.$childrenToAnimate.replace('.leftTitle, ','');
            self.$childrenToAnimate = self.$childrenToAnimate.replace('.rightTitle, ','');
        }

        if (self.$currentQuestionLeft.hasClass('QGEN') && self.$currentQuestionLeft.next().hasClass('QGEN')) {
            self.$childrenToAnimate = self.$childrenToAnimate.replace('.leftTitle, ','');
            self.$childrenToAnimate = self.$childrenToAnimate.replace('.rightTitle, ','');
            self.$childrenToAnimate = self.$childrenToAnimate.replace('.rightInstruction, ','');
        }

        // animate out
        self.$currentQuestionLeft.find(self.$childrenToAnimate).each(function(i){
            toAnimateOut.push($(this));
        });

        self.$currentQuestionRight.find(self.$childrenToAnimate).each(function(i){
            toAnimateOut.push($(this));
        });

        // handle next button
        if(self.$currentQuestionLeft.hasClass('question-1')){
            toAnimateOut.push($('.nextButton'));
        }

        $(toAnimateOut).each(function(i){
            var item = $(this);
            var i = i;
            TweenMax.fromTo(item,0.5,{alpha:1,x:0+'%'},{autoAlpha:0,x:-100+'%',force3D:true,ease:Strong.easeIn,delay:i*0.1,onStart:function(){
                if (i==0){
                    self.$currentQuestionLeft.addClass('prev');
                    self.$currentQuestionRight.addClass('prev');
                }
            },onComplete:function(){
                if (i == toAnimateOut.length-1){
                    // hide the push element that makes room for the next and finish buttons
                    $('.push').css('display','none');
                    $('.nextButtonContainer').css('display','none');
                    // animate container heights
                    TweenMax.to(self.$quizPageLeft.find('.content'),0.5,{delay:0,force3D:true,height:self.$currentQuestionLeft.next().height(),ease:Strong.easeOut});
                    TweenMax.to(self.$quizPageRight.find('.content'),0.5,{delay:0,force3D:true,height:self.$currentQuestionRight.next().height(),ease:Strong.easeOut});
                }
            }});
        });

        // animate in
        self.$currentQuestionLeft.next().find(self.$childrenToAnimate).each(function(i){
            toAnimateIn.push($(this));
        });
        self.$currentQuestionRight.next().find(self.$childrenToAnimate).each(function(i){
            toAnimateIn.push($(this));
        });

        // handle finish button
        if(self.$currentQuestionLeft.index() == self.$questions.length-2){
            $('.finishButtonContainer').css('display','block');
            toAnimateIn.push($('.finishButton'));
        }

        $(toAnimateIn).each(function(i){
            var item = $(this);
            TweenMax.fromTo(item,0.5,{alpha:0,x:50+'%'},{alpha:1,x:0+'%',force3D:true,ease:Strong.easeOut,delay:(toAnimateOut.length*0.1)+(0.65)+(i*0.1),onStart:function(){
                if (i== 0 && self.$currentQuestionLeft.index() == self.$questions.length-2) {
                    $('.push').css('display','block');

                }
            },onComplete:function(){
                if (i == toAnimateIn.length-1){
                    // set current questions
                    self.$currentQuestionLeft.removeClass('current');
                    self.$currentQuestionRight.removeClass('current');
                    self.$currentQuestionLeft = self.$currentQuestionLeft.next();
                    self.$currentQuestionRight = self.$currentQuestionRight.next();
                    self.$currentQuestionLeft.addClass('current').removeClass('next');
                    self.$currentQuestionRight.addClass('current').removeClass('next');

                    $(self.$childrenToAnimate).removeClass('inactive');
                    window.isTransitioning = false;
                }

            }});
        });
        // update pagination
        self.$currentPage++;
        self.updatePagination();

    } else {
        self.processResult();
    }
};

QuizEngine.prototype.updatePagination = function(){
    var self = this;
    var percentage = Math.round((self.$currentPage*100)/self.$questionPagesLeft.length);
    // handles visualization of progress bar
    self.$paginationPercentage.html(percentage);
    TweenMax.to(self.$paginationIndicator,0.5,{width:percentage+'%',ease:Strong.easeOut});
    // particle pagination
    self.$particlePaging.setValue(self.$currentPage);
};

QuizEngine.prototype.addToScore = function(inputKey,arch01,arch02,arch03,arch04,arch05,arch06,arch07,arch08,arch09,arch10){
    var self  = this;
    var inputKey = inputKey;

    self.$score.arch01 += inputKey * arch01;
    self.$score.arch02 += inputKey * arch02;
    self.$score.arch03 += inputKey * arch03;
    self.$score.arch04 += inputKey * arch04;
    self.$score.arch05 += inputKey * arch05;
    self.$score.arch06 += inputKey * arch06;
    self.$score.arch07 += inputKey * arch07;
    self.$score.arch08 += inputKey * arch08;
    self.$score.arch09 += inputKey * arch09;
    self.$score.arch10 += inputKey * arch10;

};

QuizEngine.prototype.processResult = function(){
    var self = this;
    // apply constants
    self.$score.arch01 += self.$constants.arch01;
    self.$score.arch02 += self.$constants.arch02;
    self.$score.arch03 += self.$constants.arch03;
    self.$score.arch04 += self.$constants.arch04;
    self.$score.arch05 += self.$constants.arch05;
    self.$score.arch06 += self.$constants.arch06;
    self.$score.arch07 += self.$constants.arch07;
    self.$score.arch08 += self.$constants.arch08;
    self.$score.arch09 += self.$constants.arch09;
    self.$score.arch10 += self.$constants.arch10;
    // calculate winner
    var winner = Object.keys(self.$score).reduce(function(a, b){
        return self.$score[a] > self.$score[b] ? a : b
    });
    // pull the profile from the archetypes object
    self.$winningArchetype = self.$archetypes.filter(function( obj ) {
      return obj.archetype == winner;
    });

    if (isIE() !== 9){
        // update location
        history.pushState('', 'Aviva Save Smarter', '?q='+self.$winningArchetype[0].slug);
    }
    // get slug
    var slug = self.$winningArchetype[0].slug;
    // create cookie
    self.$resultsCookie.createCookie('aviva-quiz-result', slug, 365);
    // populate from slug
    self.populateResultsPage(slug);
};

QuizEngine.prototype.populateResultsPage = function(url){
    var self = this;

    // process url
    self.$winningArchetype = self.$archetypes.filter(function( obj ) {
      return obj.slug == url;
    });

    var slug = self.$winningArchetype[0].slug;

    window.winningArchetype = self.$winningArchetype[0];

    if (isIE() !== 9){
        // update location
        history.pushState('', 'Aviva Save Smarter', '?q='+slug);
    }

    // add result to tracking
    AVIVA.pageData['archetype'] = self.$winningArchetype[0].title;

    // read cookie, if exists, populate content A or C, if not, content B
    if(self.$resultsCookie.readCookie('aviva-quiz-result')){

        if (self.$resultsCookie.readCookie('aviva-quiz-result') == slug){
            // USER A (just took quiz, gets own result, CTA to take quiz again)
            AVIVA.pageData['user-type'] = 'user-A';
        } else {
            // USER C (took the quiz, but its viewing another profile, CTA to view own profile, take quiz again)
            AVIVA.pageData['user-type'] = 'user-C';
            $('.missionRow, .archetypeSliderHolder, .Results .splitContainerHolder .socialLinks, .Results h2.subHeading').css('display','none');
            $('.profileLink').removeClass('hidden');
            $('.profileLink').on('click',function(e){
                e.preventDefault();
                window.location = '/?q='+self.$resultsCookie.readCookie('aviva-quiz-result');
            })
        }

    } else {
        // USER B (never took the quiz, gets the profile with a CTA to take it)
        AVIVA.pageData['user-type'] = 'user-B';
        $('.missionRow, .archetypeSliderHolder, .Results .splitContainerHolder .socialLinks, .Results h2.subHeading').css('display','none');
        $('.quizRestart').removeClass('hidden');
    }

    // populate content for all users
    var imagePath = '/assets/images/';
    self.$resultsPage.archetypeTitle.append(self.$winningArchetype[0].title);
    self.$resultsPage.quote.append(self.$winningArchetype[0].quote);
    self.$resultsPage.imageHolder.css('background-image', "url("+imagePath+self.$winningArchetype[0].gif+")");
    //self.$resultsPage.imageHolder.append('<img src="'+imagePath+self.$winningArchetype[0].image+'"/>');
    self.$resultsPage.powerRating.addClass(slug);
    self.$resultsPage.statHolder.html('<img class="statImage" src="'+imagePath+self.$winningArchetype[0].missionStats+'"/>')
    self.$resultsPage.superPowerTitle.append(self.$winningArchetype[0].superpowerTitle);
    self.$resultsPage.superPowerCopy.append(self.$winningArchetype[0].superpower);
    self.$resultsPage.kryptoniteTitle.append(self.$winningArchetype[0].kryptoniteTitle);
    self.$resultsPage.kryptoniteCopy.append(self.$winningArchetype[0].kryptonite);
    self.$resultsPage.aboutCopy.append(self.$winningArchetype[0].about);
    self.$resultsPage.missionCopy.append(self.$winningArchetype[0].mission);

    // init archetype slider
    var archetypeSlider = $('.resultSlider');
    new ArchetypeSlider($(archetypeSlider));

    // retake-quiz
    $('.retakeQuiz').on('click',function(e){
        e.preventDefault();
        self.retakeQuiz();
    });

    // bring results page
    self.bringResultsPage();

    // load tracking pixels
    self.loadTrackingPixels(slug);
};

QuizEngine.prototype.bringResultsPage = function() {
    var self = this;
    TweenMax.to($('#mainContainer'),0.75,{x:-66.6666+'%',force3D:true,ease:Strong.easeInOut,onComplete:function(){
       /* // lightning bold animation
        if(PIXI.utils.isWebGLSupported()){
            var bolt = new lightningBolt($('.bgHero'));
            bolt.setValue();
        } else {
            // fallback
            $('.bgHero').addClass('fallback');
        }*/
        // trigger resize
        TweenMax.delayedCall(0.1,function(){
            $(window).trigger('window.resize');
        })
    }});
    // change color of header border on mobile
    $('.header-bar').addClass('results');
    // tracking
    AVIVA.pageData['page-name'] = 'uk:good­thinking:fpp­quiz:results';
    // enable header link while on quiz
    self.$headerLogoInQuiz.css('pointer-events','auto');
};

QuizEngine.prototype.retakeQuiz = function(){
    var self = this;
    self.$resultsCookie.eraseCookie('aviva-quiz-result');
    window.location = '/?q=startquiz';
};

QuizEngine.prototype.setSize = function() {
    var self = this;
    // animate container heights
    TweenMax.to(self.$quizPageLeft.find('.content'),0.5,{delay:0.25,force3D:true,height:self.$currentQuestionLeft.height(),ease:Strong.easeOut});
    TweenMax.to(self.$quizPageRight.find('.content'),0.5,{delay:0.25,force3D:true,height:self.$currentQuestionRight.height(),ease:Strong.easeOut});
};

QuizEngine.prototype.loadTrackingPixels = function(profile) {
    var self = this;
    var profile = profile;
    var DCM = $('#DCM');
    var axel = Math.random() + "";
    var a = axel * 10000000000000;

    // facebook pixel
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','//connect.facebook.net/en_US/fbevents.js');fbq('init', '644100419048216');

    // outbrain
    DCM.append('<img src="http://amplifypixel.outbrain.com/pixel?mid=005f919127498f47a4ab359263bcebc593">');

    // loads correct set of pixels per archetype
    switch(profile){
        case "darkstriver":
            fbq('track', 'PageView');
            twttr.conversion.trackPid('nugx4', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=theda00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "oracle":
            fbq('track', 'ViewContent');
            twttr.conversion.trackPid('nugwt', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=theor0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "superstretcher":
            fbq('track', 'Search');
            twttr.conversion.trackPid('nugwu', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=thesu0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "turbosaver":
            fbq('track', 'AddToCart');
            twttr.conversion.trackPid('nugwv', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=turbo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "timedueller":
            fbq('track', 'AddToWishlist');
            twttr.conversion.trackPid('nugwx', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=theti0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "professorchill":
            fbq('track', 'InitiateCheckout');
            twttr.conversion.trackPid('nugwy', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=profe0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "commander":
            fbq('track', 'AddPaymentInfo');
            twttr.conversion.trackPid('nugx0', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=theco0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "enlightenedone":
            fbq('track', 'Purchase', {value: '0.00', currency:'GBP'});
            twttr.conversion.trackPid('nugx1', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=theom0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "captainsavvy":
            fbq('track', 'Lead');
            twttr.conversion.trackPid('nugx2', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=capta0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
        case "daydreamer":
            fbq('track', 'CompleteRegistration');
            twttr.conversion.trackPid('nugx3', { tw_sale_amount: 0, tw_order_quantity: 0 });
            DCM.append('<iframe src="https://1268437.fls.doubleclick.net/activityi;src=1268437;type=think001;cat=theda0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
        break;
    }
};

module.exports = QuizEngine;
