"use strict";

var WindowScroll = function() {
    var self = this;
    // set vars
    self.running = false;

    window.addEventListener('scroll', self.triggerScroll);
    self.triggerScroll();

};

WindowScroll.prototype.triggerScroll = function() {

    var self = this;

    if (!self.running) {
        self.running = true;

        window.requestAnimationFrame(function() {
            $(document).trigger('window.scroll');

            self.running = false;
        });
    }

};

module.exports = WindowScroll;
