'use strict';
// IE listener fix
if (typeof(UserAgentInfo) != 'undefined' && !window.addEventListener){
    UserAgentInfo.strtBrowser = 1;
}
window.isIE = function() {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

window.w = window.innerWidth;
window.h = window.innerHeight;
window.lodashBind = require('lodash.bind');
window.$ = require('jquery');
window.jQuery = require('jquery');
window.TweenMax = require('gsap/src/uncompressed/TweenMax');
window.ScrollTo = require('gsap/src/uncompressed/plugins/ScrollToPlugin');
window.Draggable = require ('gsap/src/uncompressed/utils/Draggable');
window.FastClick = require('fastclick');
window.queryString = require('query-string');
window.PIXI = require('./vendor/pixi');
window.removeHover = require('remove-hover');

if (isIE() == 9){
    window.Swiper = require('./vendor/swiper.legacy');
} else {
    window.Swiper = require('../../bower_components/Swiper/dist/js/swiper');
}


// main app
var AvivaQuiz = (function() {

    // tracking
    window.AVIVA = window.AVIVA || {};
    AVIVA.pageData = {'page-name':'uk:good­thinking:fpp­quiz:welcome'};

    // window events
    window.WindowResize = require('./utils/window-resize');
    window.WindowScroll = require('./utils/window-scroll');

    // utils
    window.Loading = require('./utils/loading');
    window.Cookies = require('./utils/cookies');
    window.RangeSlider = require('./utils/rangeSlider');
    window.SingleSelect = require('./utils/singleSelect');
    window.MultiSelect = require('./utils/multiSelect');
    window.ParticleNumber = require('./utils/particleNumber');
    window.SocialShare = require('./utils/socialShare');

    // modules
    window.Landing = require('../_scripts/modules/Landing');
    window.QuizEngine = require('../_scripts/modules/QuizEngine');
    window.isLandingShowing = true;
    window.ArchetypeSlider = require('../_scripts/modules/archetypeSlider');

    // quiz data holder (where all user input is stored)
    window.quizData = new Object();

    // is transitioning
    window.isTransitioning = false;

    // winning archetype
    window.winningArchetype;


    // document ready
    $(document).ready(function(){
        // loading screen
        window.loading = new Loading($('.Loading'));
        // window events
        window.windowResize = new WindowResize();
        window.windowScroll = new WindowScroll();

        // init fastclick
        window.fastclick = new FastClick(document.body);

        // init remove hover
        window.removeHover = new removeHover();

        // init cookies
        window.cookies = new Cookies();

        // init landing
        window.landing = new Landing($('.Landing'));

        // init quiz engine
        window.quizEngine = new QuizEngine($('.Quiz'));

        // init social sharing
        window.socialShare = new SocialShare();


        // init range sliders
        var rangeSlider = $('.rangeSlider');
        if (rangeSlider.length) {
            for (var i = 0; i < rangeSlider.length; i++) {
                new RangeSlider($(rangeSlider[i]));
            }
        }

        // init single selects
        var singleSelect = $('.singleSelect');
        if (singleSelect.length) {
            for (var i = 0; i < singleSelect.length; i++) {
                new SingleSelect($(singleSelect[i]));
            }
        }

    });

    // page load
    $(window).on('load', function() {

        $('#mainContainer').css('width',window.innerWidth*3); // there are three panels


        TweenMax.delayedCall(0.5,function(){
            // check cookie accept
            window.cookies.checkCookieAccept();
            // check url
            var query = queryString.parse(location.search)
            if (!jQuery.isEmptyObject(query)){
                // read url
                if (query.q == 'startquiz') {
                    landing.startQuiz();
                } else {
                    quizEngine.populateResultsPage(query.q);
                }

            } else {
                // read cookie
                if(cookies.readCookie('aviva-quiz-result')){
                    quizEngine.populateResultsPage(cookies.readCookie('aviva-quiz-result'));
                }
            }
        });



    });

    // page resize
    $(window).on('resize', function() {
        window.w = window.innerWidth;
        window.h = window.innerHeight;
        $('#mainContainer').css('width',window.innerWidth*3); // there are three panels
    });

})();
