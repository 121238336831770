'use strict';

// Constructor
var RangeSlider = function($target) {
    // set the element
    var self = this;
    self.$el = $target;
    self.$rangeOptions = $target.find('.range-option');

    //set the widths
    self.$rangeOptions.each(function(){
        $(this).css('width',(100/self.$rangeOptions.length)+'%');
    });

    // apply 'selected' class to first option
    self.$rangeOptions.first().addClass('selected');

    // create rangeSelect, rangeTrack, trackLine element
    self.$el.prepend($("<div>", {class: "trackLine"}));
    self.$el.prepend($("<div>", {class: "rangeTrack"}));
    self.$rangeTrack = self.$el.find('.rangeTrack');
    self.$rangeTrack.append($("<div>", {class: "rangeSelect"}));
    self.$rangeTrack = self.$el.find('.rangeTrack');
    self.$rangeSelect = self.$el.find('.rangeSelect');
    self.$trackLine = self.$el.find('.trackLine');
    self.$rangeSelect.append($("<div>", {class: "indicator"}));
    self.$indicator = self.$el.find('.indicator');
    self.$indicator.html(self.$el.find('.selected').attr('data-value'));

    self.$sliderWidth = self.$el.outerWidth();
    self.$rangeLength = self.$rangeOptions.length;
    self.$offset = self.$rangeSelect.outerWidth();
    self.$trackLength = (self.$sliderWidth-(self.$sliderWidth/self.$rangeLength))+self.$offset;

    // create draggable slider
    self.$gridWidth = (self.$trackLength-self.$offset)/(self.$rangeLength-1);
    Draggable.create(self.$rangeSelect, {
        type:"x",
        cursor:"pointer",
        bounds: self.$rangeTrack,
        liveSnap:true,
        snap: {
        x: function(endValue) {
            return Math.round(endValue / self.$gridWidth) * self.$gridWidth;
            }
        },
        onDrag: function(e) {
        var i = self.$rangeOptions.length;
             while (--i > -1) {
           if (this.hitTest(self.$rangeOptions[i])) {
             $(self.$rangeOptions[i]).addClass('selected');
             self.$indicator.html($(self.$rangeOptions[i]).attr('data-value'));
           } else {
             $(self.$rangeOptions[i]).removeClass('selected');
           }
        }
      }
    });

    // make range options clickable
    self.$rangeOptions.on('click',function(){
        var selectedOption = $(this);
        var optionPointerLocation = (selectedOption.position().left);
        self.$rangeOptions.removeClass('selected');
        selectedOption.addClass('selected');
        self.$indicator.html($(selectedOption).attr('data-value'));
        TweenMax.to(self.$rangeSelect,0.75,{x:optionPointerLocation, ease:Strong.easeOut});
    });

    // set the size on resize
    $(document).on('window.resize', lodashBind(this.setSize, this));
    $(window).trigger('window.resize');
    self.setSize();

};

RangeSlider.prototype.setSize = function(){
    var self = this;
    self.$sliderWidth = self.$el.outerWidth();
    self.$rangeLength = self.$rangeOptions.length;
    self.$offset = self.$rangeSelect.outerWidth();
    self.$trackLength = (self.$sliderWidth-(self.$sliderWidth/self.$rangeLength))+self.$offset;
    self.$gridWidth = (self.$trackLength-self.$offset)/(self.$rangeLength-1);
    self.$rangeTrack.css('width',self.$trackLength);
    self.$trackLine.css('width',(self.$trackLength)-(self.$offset/2));
    // update the rangeSelect on resize
    var selectedOption = self.$el.find('.selected');
    var optionPointerLocation = (selectedOption.position().left);
    TweenMax.to(self.$rangeSelect,0.75,{x:optionPointerLocation, ease:Strong.easeOut});
};

module.exports = RangeSlider;
