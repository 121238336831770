'use strict';

// Constructor
var SingleSelect = function($target) {
    // set the element
    var self = this;
    self.$el = $target;
    self.$singleselectOptions = $target.find('.singleselect-option');

    // apply 'selected' class to first option
    //self.$singleselectOptions.first().addClass('selected');

    // select option
    self.$singleselectOptions.on('click', function(e){
        e.preventDefault();
        if(!window.isTransitioning){
            self.$singleselectOptions.removeClass('selected');
            $(this).addClass('selected');
        }
    });

};

module.exports = SingleSelect;
