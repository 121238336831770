"use strict";

var WindowResize = function() {
    var self = this;
    // set vars
    self.running = false;

    window.addEventListener('resize', self.triggerResize);
    self.triggerResize();

};

WindowResize.prototype.triggerResize = function() {

    var self = this;

    if (!self.running) {
        self.running = true;

        window.requestAnimationFrame(function() {
            $(document).trigger('window.resize');

            self.running = false;
        });
    }

};

module.exports = WindowResize;
