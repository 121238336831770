'use strict';

var SocialShare = function() {
    var self = this;
    self.socialShareButton = $('.socialShare');

    self.socialShareButton.on('click',function(e){
        e.preventDefault(e);

        if ($(this).hasClass('emailGeneric')){
            self.emailGeneric();
        }

        if ($(this).hasClass('emailResult')){
            self.emailResult();
        }

        if ($(this).hasClass('facebookGeneric')){
            self.facebookGeneric();
        }

        if ($(this).hasClass('facebookResult')){
            self.facebookResult();
        }

        if ($(this).hasClass('twitterGeneric')){
            self.twitterGeneric();
        }

        if ($(this).hasClass('twitterResult')){
            self.twitterResult();
        }

    });

    // init facebook api
    window.fbAsyncInit = function() {
        FB.init({
          //appId      : '1040772829327046', // staging
          appId      : '190016381372595',
          xfbml      : true,
          version    : 'v2.5'
        });
    };

    (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "//connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

};

SocialShare.prototype.emailGeneric = function() {
    var self = this;
    window.location = 'mailto:?subject=What’s your FINANCIAL PERSONALITY?&body=I%E2%80%99ve%20just%20used%20Aviva%E2%80%99s%20Financial%20Personality%20Profiler%20to%20find%20out%20what%20kind%20of%20a%20saver%20(or%20spender)%20I%20am.%20Why%20don%E2%80%99t%20you%20try%20it%20too?%20%0A%0ADiscover%20your%20savings%20superhero%20%E2%80%93%20your%20strengths%20(and%20your%20nemesis)%20and%20take%20up%20the%20mission%20to%20be%20smarter%20with%20your%20cash.%20%0A%0ADiscover%20your%20financial%20personality%20at%20www.avivasavesmarter.co.uk';
};

SocialShare.prototype.emailResult = function() {
    var self = this;
    window.location = 'mailto:?subject=What’s your FINANCIAL PERSONALITY?&body=I%E2%80%99ve%20just%20used%20Aviva%E2%80%99s%20Financial%20Personality%20Profiler%20to%20find%20out%20what%20kind%20of%20a%20saver%20(or%20spender)%20I%20am.%20Why%20don%E2%80%99t%20you%20try%20it%20too?%20%0A%0ADiscover%20your%20savings%20superhero%20%E2%80%93%20your%20strengths%20(and%20your%20nemesis)%20and%20take%20up%20the%20mission%20to%20be%20smarter%20with%20your%20cash.%20%0A%0ADiscover%20your%20financial%20personality%20at%20www.avivasavesmarter.co.uk';
};

SocialShare.prototype.facebookGeneric = function() {
    var self = this;
    FB.ui({
        method: 'share',
        href: 'http://www.avivasavesmarter.co.uk/'
    }, function(response){
        if (response && !response.error_message) {
            // success share
        } else {
          //alert('Error while posting.');
        }
    });

};

SocialShare.prototype.facebookResult = function() {
    var self = this;
    FB.ui({
        method: 'share',
        href: 'http://www.avivasavesmarter.co.uk/'+window.winningArchetype.slug
    }, function(response){
        if (response && !response.error_message) {
            // success share
        } else {
          //alert('Error while posting.');
        }
    });

};

SocialShare.prototype.twitterGeneric = function() {
    var self = this;
    console.log('twitterGeneric');
    window.open("https://twitter.com/intent/tweet?text=What%E2%80%99s%20your%20financial%20personality?%20Discover%20your%20savings%20superhero.%20%23savesmarter%20http%3A//www.avivasavesmarter.co.uk/","new");


};

SocialShare.prototype.twitterResult = function() {
    var self = this;
    window.open("https://twitter.com/intent/tweet?text=I'm%20"+window.winningArchetype.title+".%20Discover%20your%20savings%20superhero.%20%23savesmarter%20http%3A//www.avivasavesmarter.co.uk/"+window.winningArchetype.slug,"new");

};

module.exports = SocialShare;
