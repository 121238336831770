'use strict';
var ArchetypeSlider = require('./../modules/archetypeSlider');

var Landing = function($target) {
    // set the element
    var self = this;
    self.$el = $target;
    self.$startQuiz = self.$el.find('.startQuiz');

    // start quiz
    self.$startQuiz.on('click',function(e){
        e.preventDefault();
        // start quiz
        self.startQuiz();
    });

    // init archetype slider
    var archetypeSlider = $('.landingSlider');
    var landingSlider = new ArchetypeSlider($(archetypeSlider));

};


Landing.prototype.startQuiz = function(){
    var self = this;
    var quizPage = $('.Quiz');
    var quizPagination = $('.quizPagination');

    // remove intro page
    TweenMax.fromTo($('#mainContainer'),0.75,{x:0+'%'},{x:-33.3333+'%',force3D:true,ease:Strong.easeInOut,onComplete:function(){
        quizEngine.updatePagination();
        quizEngine.bringFirstQuestion();
    }});

    /*// remove intro page
    TweenMax.fromTo(self.$el,0.75,{x:0+'%'},{x:-100+'%',force3D:true,ease:Strong.easeInOut,onComplete:function(){
        TweenMax.set(self.$el,{autoAlpha:0});
    }});

     // bring in quiz
    TweenMax.fromTo(quizPage,0.65,{x:100+'%'},{x:-100+'%',force3D:true,ease:Strong.easeInOut,onComplete:function(){
        quizEngine.updatePagination();
        quizEngine.bringFirstQuestion();
    }});*/

    // tracking
    AVIVA.pageData = {'page-name':'uk:good­thinking:fpp­quiz:quiz'};

    // clear query strings
    if (isIE() !== 9){
        history.replaceState({}, '', '/');
    }
    isLandingShowing = false;
};

module.exports = Landing;
