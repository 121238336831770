'use strict';

var ArchetypeSlider = function($target) {
    // set the element
    var self = this;
    self.$el = $target;
    self.$archetypesLoaded = false;
    self.$archetypes = [];
    self.$el.addClass('swiper-container');

    // swiper wrapepr
    self.$swiperWrapper = $('<div />', {"class": 'swiper-wrapper'});
    self.$el.append(self.$swiperWrapper);

    // create arrows
    self.$prevArrow = $('<div />', {"class": 'prevArrow Icon Icon--angle-left'});
    self.$el.append(self.$prevArrow);

    self.$nextArrow = $('<div />', {"class": 'nextArrow Icon Icon--angle-right'});
    self.$el.append(self.$nextArrow);

    // create pagination
    self.$pagination = $('<div />', {"class": 'pagination'});
    self.$el.append(self.$pagination);

    self.loadArchetypeData();
};

ArchetypeSlider.prototype.loadArchetypeData = function(){
    var self = this;
    // load archetypes
    $.getJSON("/data/archetypes.json", function(data) {
      $.each(data.archetypes,function(key,val) {
            self.$archetypes.push(val);
        });
    })
    .done(function() {
        self.$archetypesLoaded = true;
        if ($(window.winningArchetype).length){
            // filter winner from the archetype list
            var archetypeToRemove = self.$archetypes.filter(function( obj ) {
              return obj.slug == window.winningArchetype.slug;
            });
            var k = self.$archetypes.indexOf(archetypeToRemove[0]);
            self.$archetypes.splice(k,1);
            self.buildSlider();
        } else {
            self.buildSlider();
        }

    })
    .fail(function() {
        self.loadArchetypeData();
    });
};



ArchetypeSlider.prototype.buildSlider = function(){
    var self = this;
    var imagePath = '/assets/images/';

    if(self.$el.hasClass('landingSlider')){
        $(self.$archetypes).each(function(i){
            var archetype = self.$archetypes[i];
            var slide = $('<div />', {"class": 'swiper-slide'});
            slide.attr('data-slug',archetype.slug);
            self.$swiperWrapper.append(slide);

            var slideContent = $('<div />', {"class": 'slide-content'});
            slide.append(slideContent);

            var archetypeImage = $('<div />', {"class": 'archetypeImage'});
            archetypeImage.css('background-image', "url("+imagePath+archetype.image+")");
            slideContent.append(archetypeImage);

            var archetypeTitle = $('<div />', {"class": 'archetypeTitle'});
            archetypeTitle.html(archetype.title);
            slideContent.append(archetypeTitle);

            var archetypeQuote = $('<div />', {"class": 'archetypeQuote'});
            archetypeQuote.html(archetype.quote);
            slideContent.append(archetypeQuote);

            var archetypePower = $('<div />', {"class": 'archetypePower'});
            archetypePower.html(archetype.superpower);
            slideContent.append(archetypePower);

        });

        if (isIE() == 9){
            var mySwiper = self.$el.swiper({
                slidesPerView:1,
                watchActiveIndex: true,
                calculateHeight:true,
                centeredSlides: true,
                loop: true,
                pagination:'.landingSlider .pagination',
                grabCursor: false,
                paginationClickable: true,
                onSlideChangeEnd : function(swiper){
                    self.changeBackground();
                }
            });
            self.changeBackground();
            $('.landingSlider').find('.nextArrow').on('click',function(e){
                e.preventDefault();
                mySwiper.swipeNext();
            });
            $('.landingSlider').find('.prevArrow').on('click',function(e){
                e.preventDefault();
                mySwiper.swipePrev();
            });
        } else {
            // init swiper
            var mySwiper = new Swiper(self.$el, {
                direction: 'horizontal',
                speed: 500,
                loop: true,
                spaceBetween: 0,
                centeredSlides: true,
                slidesPerColumn: 1,
                slidesPerView: 1,
                pagination: '.pagination',
                paginationClickable: true,
                autoHeight: true,
                nextButton: '.nextArrow',
                prevButton: '.prevArrow',
                onSlideChangeEnd : function(swiper){
                    self.changeBackground();
                }
            });
        }

    }

    if(self.$el.hasClass('resultSlider')){
        $(self.$archetypes).each(function(i){
            var archetype = self.$archetypes[i];
            var slide = $('<div />', {"class": 'swiper-slide'});
            self.$swiperWrapper.append(slide);

            var slideContent = $('<div />', {"class": 'slide-content'});
            slide.append(slideContent);

            var archetypeImage = $('<div />', {"class": 'archetypeImage'});
            archetypeImage.css('background-image', "url("+imagePath+archetype.image+")");
            slideContent.append(archetypeImage);

            var archetypeTitle = $('<h2 />', {"class": 'archetypeTitle'});
            archetypeTitle.html(archetype.title);
            slideContent.append(archetypeTitle);

            var superPowerTitle = $('<h3 />', {"class": 'superPowerTitle'});
            superPowerTitle.html('Superpower');
            slideContent.append(superPowerTitle);

            var archetypePower = $('<p />', {"class": 'archetypePower'});
            archetypePower.html(archetype.superpowerTitle);
            slideContent.append(archetypePower);

            var kryptoniteTitle = $('<h3 />', {"class": 'superPowerTitle'});
            kryptoniteTitle.html('Nemesis');
            slideContent.append(kryptoniteTitle);

            var archetypeKryptonite = $('<p />', {"class": 'archetypePower'});
            archetypeKryptonite.html(archetype.kryptoniteTitle);
            slideContent.append(archetypeKryptonite);

        });

        if (isIE() == 9){
            var mySwiper2 = self.$el.swiper({
                slidesPerView:'auto',
                watchActiveIndex: true,
                calculateHeight:true,
                centeredSlides: true,
                pagination:'.resultSlider .pagination',
                loop: true,
                grabCursor: false,
                paginationClickable: true
            });
            $('.resultSlider').find('.nextArrow').on('click',function(e){
                e.preventDefault();
                mySwiper2.swipeNext();
            });
            $('.resultSlider').find('.prevArrow').on('click',function(e){
                e.preventDefault();
                mySwiper2.swipePrev();
            });
        } else {
            // init swiper
            var mySwiper2 = new Swiper(self.$el, {
                direction: 'horizontal',
                speed: 500,
                loop: true,
                spaceBetween: 0,
                centeredSlides: false,
                slidesPerView: 'auto',
                pagination: '.pagination',
                autoHeight: true,
                nextButton: '.nextArrow',
                prevButton: '.prevArrow',
                paginationClickable: true,
                onSlideChangeEnd : function(swiper){
                    swiper.updateContainerSize();
                }
            });
        }

    }

};

ArchetypeSlider.prototype.changeBackground = function(){
    var self = this;
    var activeSlide = $(self.$swiperWrapper).find('.swiper-slide-active').attr('data-slug');
    var backgroundContainer = $('.Landing').find('.right');
    backgroundContainer.removeClass('darkstriver oracle superstretcher turbosaver timedueller professorchill commander enlightenedone captainsavvy daydreamer');

    backgroundContainer.addClass(activeSlide);
};

module.exports = ArchetypeSlider;
