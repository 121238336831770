"use strict";

var Cookies = function() {
    // setup
    var self = this;
    self.$cookieNotice = $('.cookieNotice');
    self.$noticeHeight = self.$cookieNotice.height();
    self.$cookieNoticeShowing = false;

    // set the size on resize
    $(document).on('window.resize', lodashBind(self.setSize, self));
    $(window).trigger('window.resize');
    //window.dispatchEvent(new Event('resize'));

    // hide cookie notice initially
    TweenMax.set(self.$cookieNotice,{y:-100+'%'});

};

Cookies.prototype.checkCookieAccept = function(){
    var self = this;
    // cookie notice logic
    if (!self.readCookie('aviva-cookie-notice-accept')) {
       // no cookie, show bar
       self.showNotice();
       $('.cookie-close').on('click', function(e) {
           e.preventDefault();
           self.hideNotice();
           self.createCookie('aviva-cookie-notice-accept', true, 365);
       });
   }
};

Cookies.prototype.setSize = function(){
    var self = this;
    if (self.$cookieNoticeShowing){
        self.$noticeHeight = self.$cookieNotice.height();
    }
};

Cookies.prototype.showNotice = function(){
    var self = this;

    TweenMax.set(self.$cookieNotice,{css:{'display':'block'}});
    TweenMax.to(self.$cookieNotice,0.5,{y:0+'%',ease:Strong.easeOut,delay:1,onComplete:function(){
        self.$cookieNoticeShowing = true;
    }});
};


Cookies.prototype.hideNotice = function(){
     var self = this;
    TweenMax.to(self.$cookieNotice,0.5,{y:-100+'%',ease:Strong.easeOut,onComplete:function(){
        TweenMax.set(self.$cookieNotice,{css:{'display':'none'}});
        self.$cookieNoticeShowing = false;
    }});

};

Cookies.prototype.createCookie = function(name, value, days) {

    var expires = '';
    var date = new Date();

    if (days) {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }

    document.cookie = name + '=' + value + expires + '; path=/';
};

Cookies.prototype.readCookie = function(name) {

    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }

    return null;

};

Cookies.prototype.eraseCookie = function(name) {

    this.createCookie(name, '', -1);

};

module.exports = Cookies;
